import urlConfig from './url-config'
// Development
export const DEVELOPMENT = false;
// export const API_CLASSIFICATION_URL = ""
export const IMAGE_CONSOLE_EVENT_ID = 5; //Static Declaration 
export const AWS_S3_URL = "https://eventforce-conference.s3.ap-south-1.amazonaws.com/";
export const TYPE_SALES = 1;
export const TYPE_COMBINED = 2;
export const TYPE_FREE = 0;
export const TYPE_DONATION = 3;



//AWS S3 
export const AWS_DEV_KEY    = 'AKIASGX6F24IVE4D5SGU';
export const AWS_DEV_SECRET = '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';
export const AWS_DEV_BUCKET = 'dev-veloimages';
export const AWS_DEV_BUCKET_URL = 'https://dev-veloimages.s3.ap-south-1.amazonaws.com/';
export const APP_COMBINED = 1;
export const APP_BIB_SEARCH = 3;
export const APP_FACE_SEARCH = 2;

export const AWS_PROD_KEY    = 'AKIASGX6F24IRO7RCPPP';
export const AWS_PROD_SECRET = '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';
export const AWS_PROD_BUCKET = 'veloimages';
export const AWS_PROD_BUCKET_URL = 'https://veloimages.s3.ap-south-1.amazonaws.com/';



export const API_CLASSIFICATION_URL = urlConfig.api();

export const APP_LOGO =
  "https://api.dev.eventforce.ai/storage/velo/velo-logo.png";
  
export const POST_SHARER_URL =
  "https://post.dev.eventforce.ai/share.php?image=";
  

// Development
export const RAZORPAY_KEY = "rzp_live_jqNW4KGsWsBMWk";
export const RAZORPAY_KEY_DEV = 'rzp_test_E3rC1ZSKKrGhwj';

// DEVELOPMENT
// developer.hubcelab@gmail.com
// Project : mahachef-dev-2021
export const GOOGLE_LOGIN_CLIENT_ID =
  "989574830211-hc6r5t1ht7sv8cf19vph64v2dojcle0a.apps.googleusercontent.com";
export const GOOGLE_LOGIN_CLIENT_SECRET = "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";

// DEVELOPMENT
// hcelab6@outlook.com
// Project : Mahachef
export const FACEBOOK_APP_ID = "3071321079781461";

// //Development Server
export const __DEV__ = "dev.eventforce.ai";
export const SERVERPATH = "https://dev.eventforce.ai:8000";
export const IMAGE_PATH = "https://dev.eventforce.ai/static/media";

// Video validation
export const VIDEO_RESOLUTION = 720;
export const VIDEO_FILESIZE = 500; // 500 MB
export const VIDEO_DURATION = 300; // 5 mins = 300 sec
export const VIDEO_ALLOWED_FORMATS = ["video/mp4"];

// DROPBOX
export const DROPBOX_ACCESS_TOKEN =
  "kE6OpbGZcU4AAAAAAAAAAX1RycY5oiRk_J_LBIQBmSqVRCmxGLu9fp1cBjEjfZOc"; // Veloscope
export const DROPBOX_ACCOUNT_HOLDER = "Veloscope"; // Veloscope
export const DROPBOX_FOLDER_NAME = "MahaChefJunior2021_Test"; // Veloscope

export const TICKET_PRICE = 50;
export const DISABLE_VALIDATION = false;
export const ERROR_COMPONENT = "Error";
export const EMAIL_REGEXP =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const TEXT_ONLY_REGEXP = /^[A-Za-z\s\.]+$/; // Alphabets, dot and space are allowed
export const NUMBER_ONLY_REGEXP = /^[0-9]+$/;
export const PASSWORD_REGEXP = /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z]).{8,}$/; // Capital, small , character, number
export const PHONE_REGEXP = /^[0-9]*$/;
export const EMAIL_DEFAULT_ERROR_MSG = "Please enter a valid email address";
export const SELECTBOX_DEFAULT_ERROR_MSG = "Please select any #fieldName#";
export const TEXT_MIN_LENGTH_DEFAULT_ERROR_MSG =
  "#fieldName# should have minimum length should be #minLength#";
export const TEXT_MAX_LENGTH_DEFAULT_ERROR_MSG =
  " and maximum length should be #maxLength#";
export const TEXT_ONLY_DEFAULT_ERROR_MSG =
  "Only alphabets, space and dot are allowed in #fieldName#";
export const NUMBER_ONLY_DEFAULT_ERROR_MSG =
  "Only numbers are allowed in #fieldName#";
export const DATEPICKER_DEFAULT_ERROR_MSG = "Please select the #fieldName#";
export const EMPTY_OR_WHITESPACE_DEFAULT_ERROR_MSG = "Please fill #fieldName#";
export const PASSWORD_DEFAULT_ERROR_MSG =
  "Password should contain atleast one capital letter, small letter, special character, and number and minumum length of 8";
