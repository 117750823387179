
import React from "react";
import './style.css'
import StorageService from "../../services/StorageService";
import ApiService from "../../services/ApiService";
import ProfileImage from '../FaceRecognitionSearch/uploadImage';
import S3 from "../../services/aws/s3";
import {APP_BIB_SEARCH,APP_FACE_SEARCH, APP_COMBINED} from '../../config/constants'
import ImageGallery from "../../components/MasonryListing";
import {FiSearch} from 'react-icons/fi';
import {RiUserSearchLine} from 'react-icons/ri';
import DownloadPostButton from "../../components/DownloadPostButton";
import LoginModal from '../../components/Modal/login'
import toast from "../../services/toast";
import urlConfig from "../../config/url-config";
import { saveAs } from 'file-saver';
import helpers from "../../services/helpers";

const downloadImages =(images) => {
    for (let image of images) {
        saveAs(image, 'eventforce-'+(Math.random() + 1).toString(36).substring(7)+'.jpg') // Put your image url here.
        // let response  = await  fetch(image.replace('https://dtq9cqmh745pp.cloudfront.net/','/cloudfront/',{headers:{"Access-Control-Allow-Credentials":true}}));
        // console.log(response.blob());
    }
  }

class BidSearch extends React.Component {
    constructor(props){
        super(props);
    let campainData =  StorageService.getData("campaign_data")  ? JSON.parse(StorageService.getData("campaign_data")) : [];

    this.state ={
        isLoading:false,
        eventId:StorageService.getData('fr_event_id'),
        guestImage:StorageService.getData('guest_image'),
        showResults:false,
        imageList:[],
        isSearchEmpty:false,
        showImageUpload:false,
        selectedImages:[],
        isOpenLoginModal:false,
        campainData:campainData,
        sessionId:StorageService.getData('sessionId'),
        bg:'#fff',
        isSearching:false,
        isBibLoading:false,
        bidNumber: StorageService.getData("bidNo") ? StorageService.getData("bidNo") : "",
        is_this_sales_event:campainData?.is_sales_event!=0,
        is_dynamic_branding:campainData?.is_dynamic_branding,
        isFaceSearch:false,
        isDownloading:false,
        imageComponentKey:1,
        applicationType:campainData?.application,
    }
}

_renderBibSearch(){
    return(
        <div className="container">
                        <input value={this.state.bidNumber} onChange={event=>this.setState({bidNumber:event.target.value,isSearchEmpty:false})} placeholder="Enter your BIB no. here" className="searchBtn search"/>
                       
                        <button onClick={()=>this.handleBibSearch()} className={(this.state.isLoading || this.state.bidNumber.length < 1) ? 'guest-search-btn disabled' : 'guest-search-btn'}>{
                            this.state.isBibLoading && 'Searching...' ||
                            (this.state.isFaceSearch ? 'Verifying facial match. Please wait....' : this.state.applicationType==APP_BIB_SEARCH ? 'Search' : 'Next')
                        
                        }</button>
                       
                        <a href="/login" style={{paddingTop:15}}>Back to home</a>

                       
                    </div>
    )
}

_renderFaceSearch(){
    return(
        <div  className="container">
                        <ProfileImage
                        skipBtn={this.state.applicationType!=APP_FACE_SEARCH}
                        faceSearchImage={()=>this.searchFaceRecognition()}
                        guestImage={this.state.guestImage}
                        isFrSearching={this.state.isFaceSearch}
                        onSkipSearch={()=>{this.searchBidImages('bib');}} onCamChange={(value)=>{this.setState({bg:value ? '#000' : '#fff'})}} 
                        infoContent={'This is to search the unidentified images'} 
                        btnName={this.state.isFaceSearch ? 'Verifying facial match. Please wait....' : 'Face Search'} uploadImage={(image) => this.uploadImage(image)}/>
                    </div>
    )
}

_renderResults(){
    return(
        <ImageGallery           
        key={this.state.imageComponentKey}         
        selectedImages={this.state.selectedImages}
        onSelect={(image, isSelected)=>{
            if(isSelected) {
                this.setState({selectedImages:[...this.state.selectedImages,image]});
            } else {
                let selectedImages = this.state.selectedImages.filter(item => item.id!=image.id);
                this.setState({selectedImages});
            }              
            }}
            images={this.state.imageList}
        /> 
    )
}
    
    //Upload Image to S3
    uploadImage(image){       
       
        const type = image.split(';')[0].split('/')[1];
        this.setState({isLoading:true});          
        let timestamp = + new Date();     
        S3.upload(image,timestamp+'.'+type,(data)=>{
            this.setState({isLoading:false});
            StorageService.storeData('guest_image', data.Location);
            this.searchImages();
        });
    }


    componentDidMount(){
            // if(this.state.sessionId) {
            //     this.handleSearch(this.state.bidNumber);
            // }
    }

      /**
     * 
     * @param {*Link AWS URL} link
     * Post image to FR API 
     * 
     */
      async searchFaceRecognition()
      {
        
        if(this.state.applicationType==APP_COMBINED){
            this.searchBidImages();
        }
        this.setState({isFaceSearch:true});
        try{
        let link =  StorageService.getData('guest_image');
        let file = await helpers.getS3ImageAsBinary(link);
        let param = new FormData();
        param.append('file', new Blob([file]));
        param.append('event_id',this.state.eventId);
        param.append('bib_number',this.state.bidNumber.toUpperCase());
          
          this.setState({showImageUpload:false,isFaceSearch:true,isLoading:true})
        
          ApiService.frPostData("fr/search-faces", param, true).then(
              (response) => {
                if(response.length > 0){
                    let responseImage = response[0].matches;
                    this.setState(prevState=>({imageList:[...responseImage,...prevState.imageList]}));
                    this.saveFrImages(responseImage);
                }else {
                    toast.error("No result found using Face search");
                }      
                this.setState({isFaceSearch:false});       
              }).catch(e=>{this.setState({isFaceSearch:false})})
            }catch(err){
                this.setState({isFaceSearch:false});
                toast.error("Something went wrong. Plese try again");

            }
      }

    handleBibSearch()
    {
     let sessionId = this.state.bidNumber+this.state.eventId;  
     StorageService.storeData('sessionId', sessionId);
     StorageService.storeData('bidNo', this.state.bidNumber);
     StorageService.storeData('selectedImages', JSON.stringify([]));

     if(this.state.applicationType==APP_COMBINED){
         this.setState({showImageUpload:true});
     }else {
        this.setState({isBibLoading:true});
        this.searchBidImages('bib'); //BIB Search
     }

    }

    /**
     * This function used to save FR results in IMC Console
     */
    saveFrImages(response){
        let link =  StorageService.getData('guest_image');
        let id = Math.round(Math.random() * 1000);
        let params = response.map(item=>{
            return {
                participant_image:link,
                image_id:item.id,
                event_id:this.state.eventId,
                participant_id:id,
            }
        });
        ApiService.frPostData("fr/search-results", params, true).then(response=>{});
    }


    searchImages(){
        this.setState({isLoading:true});
        let guestImage =  StorageService.getData('guest_image');
        if(guestImage){
            this.searchFaceRecognition();
        }        
    }


    
    handleDownload(){
        this.setState({isDownloading:true});
      
        if(this.state.campainData?.guest_user_download==0 || this.state.campainData?.guest_user_download==undefined){
            this.setState({isOpenLoginModal:true});
        }else {
            let imagesIds = this.state.selectedImages.map(item=>item.id);
            ApiService.imgConsoleGetData('images/search/cloud-urls?image_id=['+imagesIds+']&event_id='+this.state.eventId+'&bib='+this.state.bidNumber.toUpperCase()).then(response=>{   
                this.saveToImageConsole('free');
                
                let originalImages = response[0].matches;
                originalImages = originalImages.filter(item=>imagesIds.includes(item.id))
                let images = originalImages.map(item=>item.image);
                downloadImages(images);
                this.setState(prevState=>({isDownloading:false,selectedImages:[],imageComponentKey:prevState.imageComponentKey+1}));
                // let params = {
                //   images: JSON.stringify(images),
                // };
                // this.uploadDownloadedImages(params);
            }).catch(err=>{
                this.setState({isDownloading:false})
                
            })
        }

    }

    saveToImageConsole(type='paid'){
        let data ={
          customer_id:Math.random() * 1000,
          payment_model_id: type== 'free' ? null : parseInt(this.state.paymentSlab?.id),
          unique_identifier:helpers.generateRandomString(16),
          event_id:this.state.eventId,
          amount:type== 'free' ? 'FREE' : this.state.totalPrice,
        }
        let imagesArr = this.state.selectedImages.map((a) => a.id);
        imagesArr.map(imageIds =>{
          data.image_id = imageIds;
         
            ApiService.imgConsolePostData("images/downloads", data).then(
              (response) => {
              // console.log(response);
              }
            );

        })
      }


    searchBidImages(){
        this.setState({isSearching:true});
        ApiService.frGetData(`tagging/search?bib=${this.state.bidNumber.toUpperCase()}&event_id=${this.state.eventId}&thumbnail=true`, {}, true).then(
            (response) => {  
                this.setState({isBibLoading:false});
                if(response.length > 0){
                    let responseImage = response[0].matches;
                    this.setState(prevState=>({imageList:[...responseImage.filter(item=>item.image!=""),...prevState.imageList],isSearching:false,isLoading:false,showResults:true,showImageUpload:false}));                                       
                }else {
                         this.setState({isSearching:false,showImageUpload:false});
                        toast.error("No result found using BIB search");
                }
        //         let responseImage = typeof response[0]?.tnails!=undefined ? response[0]?.tnails : response[0]?.matches;
        //         // if(this.state.is_dynamic_branding){
        //         //     responseImage =  response[0]?.branded;
        //         // }
        //  if(responseImage ==undefined){
        //     this.setState({isSearching:false,showImageUpload:false});
        //     toast.error("No result found using BIB search");
        //  }else
        //         if(responseImage.length > 0) {  
        //             this.setState(prevState=>({imageList:[...responseImage.filter(item=>item.image!=""),...prevState.imageList],isSearching:false,isLoading:false,showResults:true,showImageUpload:false}));                                       
        //         }else {
        //             if(type =='bib'){
        //                 this.setState({isSearching:false});
        //                 toast.error("No result found using BIB search");
        //             }
        //             this.setState(prevState=>({imageList:[...prevState.imageList],isLoading:false,showResults:true,showImageUpload:false,isSearching:false}));
        //         }               
            }).catch(err=>{this.setState({isLoading:false,showResults:true,isSearching:false,isBibLoading:false}); console.log('Error', err)})
    }
    
    render(){

        return (
            <div className="guest-container" style={{background:this.state.bg}}>
                <LoginModal isOpen={this.state.isOpenLoginModal} onClose={()=>this.setState({isOpenLoginModal:false})}/>
               <div className="guest-header">     
                    <h5>Search Images</h5>  
                    <div>
                        <FiSearch color="#fff" size={20} onClick={()=>{this.setState({showImageUpload:false,isLoading:false, imageList:[],selectedImages:[]})}} />
                        {!!this.state.guestImage && 
                            <RiUserSearchLine color="#fff" style={{paddingLeft:20}} size={20} onClick={()=>{this.setState({showImageUpload:true})}} />
                        }
                    </div>                       
               </div>
               {(this.state.isFaceSearch && this.state.imageList.length > 0) &&  <p style={{fontSize:12,textAlign:'center'}}>Searching for matching faces. Please hold on...</p> }
                
                {/* BIB Search Box */}
                    {(this.state.applicationType!=APP_FACE_SEARCH && this.state.imageList.length < 1 && !this.state.showImageUpload) && this._renderBibSearch()}  
                {/* //BIB Search Box */}

                {/* Face Search */}
                    {((this.state.applicationType==APP_FACE_SEARCH && this.state.imageList.length < 1 ) || (this.state.applicationType==APP_COMBINED && this.state.showImageUpload)) && this._renderFaceSearch()}
                {/* /Face Search */}

                {/* Results */}
                    {(this.state.showResults || !this.state.isFaceSearch) && (this.state.imageList.length > 0) && this._renderResults()}
                {/* //Results */}
                 
                 
                 {this.state.selectedImages.length > 0 &&
                <DownloadPostButton
                        count={this.state.selectedImages.length}
                        type='btn_save'
                        onClickDownloadButton={()=>{ StorageService.storeData('selectedImages', JSON.stringify(this.state.selectedImages)); this.handleDownload()}}
                        endText={!this.state.isDownloading ? "Download" : 'Downloading'}
                />
          }

            </div>
        )
    }
}

export default BidSearch;
