import React, { useEffect, useState } from 'react';
import { GrAddCircle, GrSubtractCircle } from 'react-icons/gr';

const DraggableText = ({ text, cordinates }) => {
  const [position, setPosition] = useState(cordinates);
  const [fontSize, setFontSize] = useState(12);

  useEffect(() => {
    setPosition(cordinates);
  }, [cordinates]);

  const handleDragStart = (e) => {
    e.preventDefault();
    const clientX = e.type === 'touchstart' ? e.touches[0].clientX : e.clientX;
    const clientY = e.type === 'touchstart' ? e.touches[0].clientY : e.clientY;

    const offsetX = clientX - position.x;
    const offsetY = clientY - position.y;

    const handleDragMove = (e) => {
      const clientX = e.type === 'touchmove' ? e.touches[0].clientX : e.clientX;
      const clientY = e.type === 'touchmove' ? e.touches[0].clientY : e.clientY;

      const newX = clientX - offsetX;
      const newY = clientY - offsetY;
      setPosition({ x: newX, y: newY });
    };

    const handleDragEnd = () => {
      document.removeEventListener('mousemove', handleDragMove);
      document.removeEventListener('touchmove', handleDragMove);
      document.removeEventListener('mouseup', handleDragEnd);
      document.removeEventListener('touchend', handleDragEnd);
    };

    document.addEventListener('mousemove', handleDragMove);
    document.addEventListener('touchmove', handleDragMove);
    document.addEventListener('mouseup', handleDragEnd);
    document.addEventListener('touchend', handleDragEnd);
  };

  const handleFontSizeChange = (increase) => {
    setFontSize((prevSize) => (increase ? prevSize + 2 : prevSize - 2));
  };

  return (
    <div
      style={{
        position: 'absolute',
        left: position.x,
        top: position.y,
        cursor: 'move',
        zIndex: 999,
        display: 'flex',
        color: '#fff',
        fontWeight: 600,
      }}
      onMouseDown={handleDragStart}
      onTouchStart={handleDragStart}
    >
      <span style={{ fontSize: `${fontSize}px`, cursor: 'move' }}>
        {text}
      </span>
      <div className="no-print" style={{ paddingLeft: 10, cursor: 'pointer' }}>
        <GrAddCircle onClick={() => handleFontSizeChange(true)} />
        <GrSubtractCircle onClick={() => handleFontSizeChange(false)} />
      </div>
    </div>
  );
};

export default DraggableText;
