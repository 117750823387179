
import React from "react";
import "./style.css";
import { withRouter } from "react-router";
import LeftDrawer from "../../components/leftDrawer";
import StorageService from "../../services/StorageService";
import ApiService from "../../services/ApiService";
import paymentGateway from "../../helpers/payment-gateway";
import { saveAs } from 'file-saver';
import helpers from "../../services/helpers";

const downloadImages =(images) => {
    for (let image of images) {
        saveAs(image, 'eventforce-'+(Math.random() + 1).toString(36).substring(7)+'.jpg') // Put your image url here.
        // let response  = await  fetch(image.replace('https://dtq9cqmh745pp.cloudfront.net/','/cloudfront/',{headers:{"Access-Control-Allow-Credentials":true}}));
        // console.log(response.blob());
    }
  }


class DonationScreen extends React.Component
{
  
    constructor(props){
        super(props);
        let campainData =  StorageService.getData("campaign_data")  ? JSON.parse(StorageService.getData("campaign_data")) : [];
        this.state = {
            totalPrice:10,
            selectedImages: StorageService.getData("selectedImages")  ? JSON.parse(StorageService.getData("selectedImages")) : [],
            campaignData:  campainData,
            amountError:'',
        frEventId:      StorageService.getData("fr_event_id"),

            paymentBtnLoading:false,
        };
    }


    openPaymentGateway(){
        if(this.state.totalPrice < 10) {
            this.setState({amountError:'Donation amount should be greater than or equal to 10.'});
            return;
        }
        this.setState({paymentBtnLoading:true})
        paymentGateway.displayRazorpay(this.state, true,
            (onSuccess)=>{
                this.saveImagesToProfile(true);

            },
            (onError)=>{
                console.log('On Error', onError);
                this.setState({paymentBtnLoading:false})

            }
            
            );
    }

    //Download Images
    saveImagesToProfile(isDonated = false){
          let imagesIds = this.state.selectedImages.map(item=>item.id);
          let type = isDonated ?  'raw_images' : 'branded_images';
          let params  = 'image_id=['+imagesIds+']&event_id='+this.state.frEventId+'&type='+type;
          if(this.state.bidNumber){
            params += `&bib=${this.state.bidNumber.toUpperCase()}`; 
          }
            ApiService.imgConsoleGetData('images/search/cloud-urls?'+params).then(response=>{
                if(isDonated){
                  this.saveToImageConsole();
                }else {
                  this.saveToImageConsole('free');
                }
                let originalImages = response[0].matches;
                originalImages = originalImages.filter(item=>imagesIds.includes(item.id))
                let images = originalImages.map(item=>item.image);
                downloadImages(images);
                let params = {
                  images: JSON.stringify(images),
                };
                this.uploadDownloadedImages(params);
            })
        
        }
  
        uploadDownloadedImages(params){
          ApiService.postData("uploadImages", params, true).then(
            (response) => {
              StorageService.storeData('selectedImages',JSON.stringify([]));
              this.setState({selectedImages:[]});
              if (response.status == "success") {
                this.props.history.push("/my-downloads?app_type=photo-app");
              } else {
                alert('Something went wrong. Please try again later');
              }
            }
          );
        }

         /*********************** Save Images to Image Console ************/
      saveToImageConsole(type='paid'){
        let data ={
          customer_id:this.state.userDetails?.id,
          payment_model_id: type== 'free' ? null : parseInt(this.state.paymentSlab?.id),
          unique_identifier:helpers.generateRandomString(16),
          event_id:this.state.frEventId,
          amount:type== 'free' ? 'FREE' : this.state.totalPrice,
        }
        let imagesArr = this.state.selectedImages.map((a) => a.id);
        imagesArr.map(imageIds =>{
          data.image_id = imageIds;
         
            ApiService.imgConsolePostData("images/downloads", data).then(
              (response) => {
              // console.log(response);
              }
            );

        })



      }


    render(){
        return(
            <div className="pageOuter" >   
                    <div className="privacyGreenBlock">
                        <div className="headerOuter">
                            <LeftDrawer></LeftDrawer>
                            <h4 className="headerTitle"></h4>
                        </div>
                       
                        <div className="ppOuter">
                            <h4 className="ppTitle">Donation</h4>
                            <h3 className="ppTitle"></h3>
                        </div>   
                        </div>
                        <div style={{display:'flex',justifyContent:'space-between'}}>
                            <div style={{paddingRight:20,paddingLeft:20,paddingTop:50,paddingBottom:50}}>
                             <h4>Dear Participants,</h4>
                             <p>While enjoying your branded images, we would like you to extend your helping hand a bit more by supporting the <b>“TERMINALLY ILL CANCER PATIENTS”,</b> by donating minimum Rs.10/- or more (Download your original images/photos without Branding). </p>

                             <h5>“You're not alone, we're in this together.”</h5>
                             <p style={{fontSize:12}}><b>Note:</b> Any donation of Rs.5000/- and above, you will receive 80G tax exemption certificate.</p>

                             <div>
                                <div>
                                    <label style={{fontWeight:500,marginBottom:5}}>Enter Amount</label>
                                    <input
                                    onChange={event=>this.setState({totalPrice:event.target.value,amountError:''})}
                                    style={{width:'100%',height:50,fontSize:18,borderRadius:8,border:"1px solid #E5E4E2"}} type="number" value={this.state.totalPrice} ></input>
                                    <span style={{fontSize:12,color:'#ff0000'}}>{this.state.amountError}</span>
                                </div>
                                <button
                                    onClick={()=>{this.openPaymentGateway()}}
                                    className="searchBtn"            
                                        >{this.state.paymentBtnLoading ? 'Loading...' :'Donate & Download (Original)'}</button>

                                    <p style={{textAlign:'center'}}>OR</p>
                                    <button
                                    onClick={()=>{this.saveImagesToProfile()}}
                                    className="searchBtn"            
                                        >Download (Branded)</button>
                             </div>
                             </div>
                         </div>
            </div>

        )
    }
}

export default withRouter(DonationScreen);
