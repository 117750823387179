import S3 from 'aws-sdk/clients/s3';
import {Buffer} from 'buffer';
import urlConfig from '../../config/url-config';
import StorageService from '../StorageService';
let BucketName = urlConfig.s3Config('bucket');

const s3Bucket = new S3({
    Bucket: urlConfig.s3Config('bucket'),   
    accessKeyId: urlConfig.s3Config('key'),
    secretAccessKey:  urlConfig.s3Config('secret'),
    signatureVersion:'v4',
    region:'ap-south-1',
    
});

const  base64Mime = (encoded)=> {
    var result = null;
  
    if (typeof encoded !== 'string') {
      return result;
    }
  
    var mime = encoded.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/);
  
    if (mime && mime.length) {
      result = mime[1];
    }
  
    return result;
  }


let folder = 'FR/FACE_SEARCH'
const awsS3 =  {
    uploadCertificate(file,fileName,callbackUrl){
        let campaign = StorageService.getData("campaign_data")  ? JSON.parse(StorageService.getData("campaign_data")) : [];
        folder = campaign?.title!=undefined ? campaign?.title+'/user/certificate' : 'user/certificate';
        var buf = Buffer.from(file.replace(/^data:image\/\w+;base64,/, ""),'base64')
        console.log('File Upload',folder+'/'+fileName);
        const params = { 
            Bucket: BucketName, 
            Key: folder+'/'+fileName,
            Body:buf,
            ContentEncoding: 'base64',
            contentType:base64Mime(file),
            ACL:'public-read',
        };
        return  s3Bucket.upload(params,(err,data)=>{
            if(err){       
                console.log(err);         
                return false
            }
            callbackUrl(data);
        });  
    }, 
    upload(file,fileName,callbackUrl){
        let campaign = StorageService.getData("campaign_data")  ? JSON.parse(StorageService.getData("campaign_data")) : [];
        folder = campaign?.title!=undefined ? campaign?.title+'/'+folder : folder;
        var buf = Buffer.from(file.replace(/^data:image\/\w+;base64,/, ""),'base64')
        console.log('File Upload',folder+'/'+fileName);
        const params = { 
            Bucket: BucketName, 
            Key: folder+'/'+fileName,
            Body:buf,
            ContentEncoding: 'base64',
            contentType:base64Mime(file),
            ACL:'public-read',
        };
        return  s3Bucket.upload(params,(err,data)=>{
            if(err){       
                console.log(err);         
                return false
            }
            callbackUrl(data);
        });  
    }, 
    listBukets(success){

        var params = { 
            Bucket: BucketName,
            Delimiter: '/',           
            // Prefix:''
           }
        s3Bucket.listObjects(params, function (err, data) {
            if(err)throw err;
            const {CommonPrefixes} = data;
            success(CommonPrefixes);
           });
    },
    getObjectData(folder,success){
        var params = { 
            Bucket: BucketName,
            Delimiter: '/',           
            Prefix:folder,
           }
        s3Bucket.listObjectsV2(params, function (err, data) {
            if(err)throw err;
            success(data.Contents);
           });
    }    
}

export default awsS3;