import React, { useState, useEffect } from 'react';
import PreviewImage from './preview'
import DraggableText from './text'
import StorageService from "../../services/StorageService";

import './style.css';


function calculateProportionalDimensions(originalWidth, originalHeight, originalBoxWidth, originalBoxHeight, newWidth) {
    // Calculate the width ratio
    const widthRatio = newWidth / originalWidth;
  
    // Calculate the new box width based on the width ratio
    const newBoxWidth = originalBoxWidth * widthRatio;
  
    // Calculate the height ratio based on the smaller dimension to preserve aspect ratio
    const heightRatio = Math.min(newWidth / originalWidth, newBoxWidth / originalBoxWidth);
  
    // Calculate the new box height based on the height ratio
    const newBoxHeight = originalBoxHeight * heightRatio;
  
    return { width:newBoxWidth,height:newBoxHeight };
  }

  function calculateRenderedCoordinates(originalResolution, renderedResolution, referencePoint) {
    const originalX = originalResolution.x;
    const originalY = originalResolution.y;

    const renderedX = renderedResolution.x;
    const renderedY = renderedResolution.y;

    const referenceX = referencePoint.x;
    const referenceY = referencePoint.y;

    // Calculate the ratio
    const xRatio = renderedX / originalX;
    const yRatio = renderedY / originalY;

    // Calculate the corresponding coordinates
    const renderedXCoordinate = Math.round(referenceX * xRatio);
    const renderedYCoordinate = Math.round(referenceY * yRatio);

    return { x: renderedXCoordinate, y: renderedYCoordinate };
}

  

const TemplateImage = ({image, onChangeHeight, onChangeWidth}) => {
  const templateUrl = '/frame-1.png';
  
  const [imageSrc, setImageSrc] = useState(image);
  const [isLoading,setIsLoading] = useState(true);
  const [imageWidth, setImageWidth] = useState(null);
  const [imageHeight, setImageHeight] = useState(null);
  const [userDetails,setUserDetails] = useState(JSON.parse(StorageService.getData("user_details")));
  const [renderImageResolution,setRenderImageResolution] = useState({width:308,height:420});
  
  const [renderAxisImage, setRenderAxisImage] = useState({x:90,y:94}); 

  const [renderAxisName, setRenderAxisName] = useState({x:450,y:296});
  const [renderAxisTime, setRenderAxisTime] = useState({x:450,y:415});
  
  
//   useEffect(() => {
//     const imageElement = document.getElementById('myImage');
//     console.log(imageElement.offsetHeight);
//     setRenderedWidth(imageElement.offsetWidth);
//     setRenderedHeight(imageElement.offsetHeight);
//     let newValue = calculateProportionalDimensions(imageWidth, imageHeight, 783, 1124,imageElement.offsetWidth );
//     setBoxResolution(newValue);
//     console.log(newValue, imageWidth, imageHeight, 783, 1124,imageElement.offsetWidth );
//   }, []);

const TDimension = (event) =>{
  let target = event.target;
  onChangeHeight(event.target.naturalHeight);
  onChangeWidth(event.target.naturalWidth);

  setImageHeight(event.target.naturalHeight);
  setImageWidth(event.target.naturalWidth);
 

    let originalResolution = {x:target.naturalHeight,y:target.naturalWidth};
    let renderedResolution = {x:target.height,y:target.width};
    
    // Calculate Image Render Axis
    setRenderAxisImage(calculateRenderedCoordinates(originalResolution,renderedResolution, renderAxisImage));
  
    //Calculate Image Render Resolution
    setRenderImageResolution(calculateProportionalDimensions(target.naturalWidth, target.naturalHeight, renderImageResolution.width, renderImageResolution.height, target.clientWidth ));
    
    //Calculate Name Render Axis
    setRenderAxisName(calculateRenderedCoordinates(originalResolution,renderedResolution, renderAxisName));

     //Calculate Time Render Axis
     setRenderAxisTime(calculateRenderedCoordinates(originalResolution,renderedResolution, renderAxisTime));
   
    setIsLoading(false);
}

  return (
    // <div className="pageOuter">
    // <div className="headerGreenBlock">
    //   <div className="headerOuter">
    <>
        {/* <button onClick={Download}>Download</button> */}
            {/* <input type="file"  accept="image/*" onChange={handleImageUpload} /> */}
        <div style={{position:'relative'}} id="certificate">
            <img src={templateUrl} id="myImage" className='imagetemplate' onLoad={TDimension} alt="Template Image" />          
            {
              !isLoading && <>
                <DraggableText text={userDetails.name} cordinates={renderAxisName}/>
                <DraggableText text={'00:45:45'} cordinates={renderAxisTime}/>
                 {imageSrc && <PreviewImage x={renderAxisImage.x} y={renderAxisImage.y} width={renderImageResolution?.width} height={renderImageResolution?.height} imageSrc={imageSrc} />}
              </>
            }
          
        </div>
        </>
    // </div>    
    // </div>
    // </div>

  );
};

export default TemplateImage;