import { AWS_DEV_BUCKET,AWS_DEV_KEY,AWS_DEV_SECRET, AWS_DEV_BUCKET_URL,AWS_PROD_BUCKET,AWS_PROD_BUCKET_URL,AWS_PROD_KEY,AWS_PROD_SECRET } from "./constants";
import StorageService from "../services/StorageService";
export const DEVELOPMENT_TYPE = 'dev'; // local | dev | live

export default {
  isCertificateApp:function(){
    return false;
  },
  isDevelopment:function(){
    return DEVELOPMENT_TYPE == 'local';
  },
    api:function(){
        switch(DEVELOPMENT_TYPE){
          case 'local':
            return 'http://www.eventforce.local/api/';
          case 'dev':
            return 'https://dev.api.eventforce.ai/api/';
          case 'live':
              return 'https://api.eventforce.ai/api/';
        }
    },
    imageConsole:function(){
      let campainData =  StorageService.getData("campaign_data")  ? JSON.parse(StorageService.getData("campaign_data")) : [];
      let point_to = campainData?.point_to_imc==undefined ? 'prod' : campainData?.point_to_imc;
      switch(DEVELOPMENT_TYPE){
        case 'local':
          return 'https://dev.imageconsole.eventforce.ai';
          case 'dev':
            return  point_to !='prod' ? 'https://dev.imageconsole.eventforce.ai' : 'https://imageconsole.eventforce.ai'; //This was a quick fix.  
            case 'live':
            return  point_to !='prod' ? 'https://dev.imageconsole.eventforce.ai' : 'https://imageconsole.eventforce.ai'; //This was a quick fix. 
      }
    },
    s3Config(type){
      switch(type){
        case 'bucket':
          return this.s3Bucket();   
          case 'bucket_url':
            return this.s3BucketUrl();   
            case 'key':
            return this.s3BucketKey();   
            case 'secret':
              return this.s3BucketSecret();   
                 
      }
    },
    s3Bucket(){
      let campainData =  StorageService.getData("campaign_data")  ? JSON.parse(StorageService.getData("campaign_data")) : [];
      let point_to = campainData?.point_to_imc==undefined ? 'prod' : campainData?.point_to_imc;
      return (DEVELOPMENT_TYPE =='live' && point_to =='prod') ? AWS_PROD_BUCKET :AWS_DEV_BUCKET;
    },
    s3BucketUrl(){
      let campainData =  StorageService.getData("campaign_data")  ? JSON.parse(StorageService.getData("campaign_data")) : [];
      let point_to = campainData?.point_to_imc==undefined ? 'prod' : campainData?.point_to_imc;
      return (DEVELOPMENT_TYPE =='live' && point_to =='prod') ? AWS_PROD_BUCKET_URL : AWS_DEV_BUCKET_URL;
    },
    s3BucketKey(){
      let campainData =  StorageService.getData("campaign_data")  ? JSON.parse(StorageService.getData("campaign_data")) : [];
      let point_to = campainData?.point_to_imc==undefined ? 'prod' : campainData?.point_to_imc;

      return (DEVELOPMENT_TYPE =='live' && point_to =='prod') ? AWS_PROD_KEY : AWS_DEV_KEY;
    },
    s3BucketSecret(){
      let campainData =  StorageService.getData("campaign_data")  ? JSON.parse(StorageService.getData("campaign_data")) : [];
      let point_to = campainData?.point_to_imc==undefined ? 'prod' : campainData?.point_to_imc;
      return (DEVELOPMENT_TYPE =='live' && point_to =='prod') ? AWS_PROD_SECRET : AWS_DEV_SECRET;
    }
}